import { destr } from 'destr'
import { events } from './events'
import { useEvent } from './framework'

const HANDLE = {
  UPHOLSTERY: 'upholstery',
  NO_UPHOLSTERY: 'no-upholstery',
  PLASTIC: 'polypropylene',
}

/**
 * global bimbear api
 * to interact with bimbear/3d
 */
// const Bimbear = () => {
//   const canvas = document.createElement('canvas')
//   const configurator = createProductConfigurator(canvas, {
//     useCameraAlignment: true,
//   })

//   canvas.classList.add('bimbear-canvas')

//   useEvent.listen(
//     events.window.resize,
//     () => {
//       if (!canvas?.parentElement) return

//       const { width, height } = canvas.parentElement.getBoundingClientRect()
//       canvas.width = width
//       canvas.height = height
//     },
//     { global: true }
//   )

//   const observer = configurator.onStateChange.add(state => {
//     if (state === 2) {
//       useEvent.dispatch(events.bimbear.initialized, true)
//       configurator.onStateChange.remove(observer)

//       setTimeout(() => {
//         canvas.classList.add('loaded')
//       }, 10)
//     }
//   })

//   return {
//     canvas,
//     configurator,
//     /**
//      * to not having to create new webgl contexts on each page shift
//      * we just move the already made canvas around in the dom.
//      * when loading up a new product, the canvas is moved in place and load() is called
//      */
//     mount: target => {
//       canvas.style.removeProperty('display')
//       target.append(canvas)
//       const { width, height } = target.getBoundingClientRect()

//       if (!target) return

//       canvas.width = width
//       canvas.height = height
//     },
//     unmount: () => {
//       canvas.style.display = 'none'
//       document.body.append(canvas)
//     },
//   }
// }

// export const bimbear = {} // Bimbear()

// debug
// window.bimbear = bimbear

// function getUrlParam(param) {
//   const url = new URL(window.location)
//   return url.searchParams.get(param)
// }

function getUrlParams() {
  const url = new URL(window.location)
  const data = [...url.searchParams.entries()]
  return data.map(entry => ({ type: entry[0], handle: entry[1] }))
}

let upholsteryContinue = []
let productUpholsteryContinueCache = null

window.addEventListener(events.window.navigation, () => {
  productUpholsteryContinueCache = null
})

try {
  const res = await fetch('?section_id=configurator-all-options')
  const text = await res.text()
  const parser = new DOMParser()
  const markup = parser.parseFromString(text, 'text/html')

  const data = destr(
    markup.getElementById('configurator-all-options').innerHTML
  ).upholstery

  upholsteryContinue = upholsteryContinue.concat(data)
} catch (error) {
  console.log(`couldn't load more upholstery: `, error)
}

try {
  const res = await fetch('?section_id=configurator-all-options-2')
  const text = await res.text()
  const parser = new DOMParser()
  const markup = parser.parseFromString(text, 'text/html')

  const data = destr(
    markup.getElementById('configurator-all-options-2').innerHTML
  ).upholstery

  upholsteryContinue = upholsteryContinue.concat(data)
} catch (error) {
  console.log(`couldn't load more upholstery: `, error)
}

async function getConfiguratorOptions2() {
  // console.log('productUpholsteryContinueCache', productUpholsteryContinueCache)
  try {
    if (productUpholsteryContinueCache) {
      return productUpholsteryContinueCache
    }

    const res = await fetch('?section_id=configurator-product-options-2')
    const text = await res.text()
    const parser = new DOMParser()
    const markup = parser.parseFromString(text, 'text/html')

    productUpholsteryContinueCache = destr(
      markup.getElementById('configurator-product-options-2').innerHTML
    )

    return productUpholsteryContinueCache
  } catch (error) {
    console.log(`couldn't load more upholstery: `, error)
  }
}

export const getConfiguratorOptions = () => {
  try {
    const scriptTag = document.querySelector('#configurator-all-options')

    if (!scriptTag) return null

    const json = destr(scriptTag.innerHTML)

    const jsonMerged = {
      ...json,
      upholstery: json.upholstery?.concat(upholsteryContinue),
    }

    // console.log(json, jsonMerged)

    return jsonMerged
  } catch (error) {
    console.warn(error)
    return {}
  }
}

export const getProductConfiguratorData = async () => {
  const scriptTag = document.querySelector('#configurator-product-options')
  const productUpholsteryContinue = await getConfiguratorOptions2()

  if (!scriptTag) return null

  const json = destr(scriptTag.innerHTML)

  // console.log('json', productUpholsteryContinue)

  // console.log('configurator-product-options', json)

  const jsonMerged = {
    ...json,
    product_options: [
      ...json.product_options.map(item => {
        // return item
        if (item.type !== 'upholstery') return item
        return {
          ...item,
          options: item.options.concat(productUpholsteryContinue.options),
        }
      }),
    ],
  }

  // rm empty/unused options
  const parsed = {
    ...jsonMerged,
    product_options: jsonMerged.product_options.filter(option => {
      return option.options.length > 0
    }),
    variants: jsonMerged.variants.map(variant => {
      return {
        ...variant,
        options: variant.options.filter(option => {
          return option.handle
        }),
      }
    }),
  }

  return parsed
}

export const getCurrentOptions = async ({ asObject = false } = {}) => {
  const allData = getConfiguratorOptions()
  const productData = await getProductConfiguratorData()
  const urlData = getUrlParams()

  // console.log('urlData', urlData)

  if (!productData) return null

  // console.log(
  //   'productData.product_options',
  //   JSON.parse(JSON.stringify(productData.product_options))
  // )

  let defaultOptions = productData.product_options.map(option => {
    const optionInParam = urlData.find(param => param.type === option.type)

    // console.log(
    //   'option.option',
    //   optionInParam?.handle,
    //   JSON.parse(JSON.stringify(option.options)),
    //   option.options.find(option => option.handle === optionInParam?.handle)
    // )

    const { handle, name } = optionInParam
      ? option.options.find(
          option => option.handle === optionInParam?.handle
        ) || {}
      : option.options[0]

    const isUpholstery = option.type === HANDLE.UPHOLSTERY
    const family = {}

    // cumbersome way of saving the name of the currently chosen color name
    if (isUpholstery) {
      family.handle = allData?.upholstery?.find(type => {
        const color = type.colors?.find(color => color.handle === handle)

        if (color) {
          family.name = color.name
          family.material = type.material
        }

        return color
      })?.handle
    }

    return {
      type: option.type,
      handle,
      family: family.handle,
      name: family.name || name,
      material: family.material,
    }
  })

  /**
   * make plastic option work
   */
  const defaultShellOption = defaultOptions.find(
    option => option.type === 'shell'
  )
  const defaultUpholsteryOption = defaultOptions.find(
    option => option.type === 'upholstery'
  )
  const isPlastic = defaultShellOption?.handle === HANDLE.PLASTIC

  if (isPlastic && defaultUpholsteryOption.family !== HANDLE.PLASTIC) {
    defaultOptions = defaultOptions.map(option => {
      if (option.type !== HANDLE.UPHOLSTERY) return option

      const plastic = allData.upholstery?.find(
        option => option.handle === HANDLE.PLASTIC
      )

      if (plastic) {
        return {
          type: HANDLE.UPHOLSTERY,
          ...plastic.colors[0],
        }
      }

      return option
    })
  }

  return asObject
    ? defaultOptions.reduce((acc, cur) => {
        return { ...acc, [cur.type]: cur }
      }, {})
    : defaultOptions
}

export const setOption = (type, handle) => {
  const url = new URL(window.location)

  const isNoUpholsteryOption = handle =>
    handle === HANDLE.NO_UPHOLSTERY || handle === HANDLE.PLASTIC

  const currentShellOption = url.searchParams.get('shell')

  if (handle === 'null') {
    url.searchParams.delete(type)
  } else {
    url.searchParams.set(type, handle)

    if (type === 'shell' && isNoUpholsteryOption(handle)) {
      url.searchParams.delete('upholstery')
    }

    // remove any existing "upholstery" option if we go from any upholstery-shell to any no-upholstery-shell,
    // or vice versa
    if (
      type === 'shell' &&
      isNoUpholsteryOption(handle) !== isNoUpholsteryOption(currentShellOption)
    ) {
      url.searchParams.delete('upholstery')
    }
  }

  window.history.replaceState(null, '', url.toString())

  useEvent.dispatch(events.configurator.optionUpdate)

  return true
}

/**
 * returns a variant if all current options matches that variant
 */
export const getChosenVariant = async () => {
  const productData = await getProductConfiguratorData()
  const currentOptions = await getCurrentOptions({ asObject: true })

  // return the variant that matches all current options
  return productData.variants?.find(variant => {
    if (!variant?.options.length) return false

    return variant?.options?.every(option => {
      return currentOptions[option.type]?.handle === option?.handle
    })
  })
}

/**
 * returns weather or not the state is to only show available variants' options
 */
export const shouldShowOnlyAvailableOnlineOptions = () => {
  const url = new URL(window.location)

  if (!checkbox.checked) {
    url.searchParams.delete('available', 'true')
  } else {
    url.searchParams.set('available', 'true')
  }
}

/**
 * change ?variant=12345 to all that variant's options; ?frame=oak&upholstery=dune
 */
export const translateUrlVariantToOptions = async () => {
  const productData = await getProductConfiguratorData()

  const url = new URL(window.location)
  const variantId = url.searchParams.get('variant')

  if (!variantId) return

  const id = parseInt(variantId)
  const variant = productData?.variants?.find(variant => variant.id === id)

  if (!variant) return

  variant.options.forEach(option => {
    setOption(option.type, option.handle)
  })

  const newUrl = new URL(window.location)
  newUrl.searchParams.delete('variant')

  window.history.replaceState(null, '', newUrl.toString())
}
