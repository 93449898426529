import { events } from '../events'
import { useEvent } from '../framework'
import { getCurrentOptions } from '../configurator'

export default async (ref, __devmode) => {
  if (!ref.mainProductBimbearViewer) return

  const [bimbearViewer] = ref.mainProductBimbearViewer

  const canvas = document.createElement('canvas')
  const configurator = createProductConfigurator(canvas, {
    useCameraAlignment: true,
  })

  canvas.classList.add('bimbear-canvas')

  bimbearViewer.append(canvas)

  const observer = configurator.onStateChange.add(state => {
    if (state === 2) {
      useEvent.dispatch(events.bimbear.initialized, true)
      configurator.onStateChange.remove(observer)

      setTimeout(() => {
        canvas.classList.add('loaded')
      }, 10)
    }
  })

  function init3dToggleButton() {
    if (!ref.toggle3d) return

    ref.toggle3d.forEach(button => {
      button.addEventListener('click', () => {
        bimbearViewer.classList.toggle('interactive')
        button.classList.toggle('interactive')
      })
    })
  }

  async function handleUpdate() {
    const currentOptions = await getCurrentOptions()

    let hasPreview = true

    // translate plastic color options from "upholstery" to "color" (for bimbear)
    if (
      currentOptions.find(
        option => option.type === 'shell' && option.handle === 'polypropylene'
      )
    ) {
      currentOptions.forEach(option => {
        if (option.type === 'upholstery') {
          option.type = 'color'
        }
      })
    }

    const shellFirst = (a, b) => {
      return a.handle === 'shell' ? 1 : -1
    }

    currentOptions.sort(shellFirst)

    for (const option of currentOptions) {
      try {
        // overwrite this specific one.. shopify can't have dot or space on handle
        if (option.handle === '2-5-seater') {
          option.handle = '2.5 seater'
        }
        if (option.type === 'shell' && option.handle === 'polypropylene') {
          //   option.handle = 'polypropylene'
          console.log('option', option)
        }

        const res = await configurator.setOption(option.type, option.handle)

        if (__devmode) {
          console.log('res', res)
        }
      } catch (error) {
        hasPreview = false

        console.log('error', error)
      }
    }

    useEvent.dispatch(events.product.hasPreview, hasPreview)
  }

  try {
    // load up current product
    try {
      await configurator.load(bimbearViewer.dataset.id)
    } catch (error) {
      await configurator.load(bimbearViewer.dataset.cylindoId)
    }

    // set initial options
    // (just throws errors when passed as object in load())
    handleUpdate()

    // place <canvas> in the correct place in dom
    // bimbear.mount(bimbearViewer)

    // inform app we loaded fine :)
    useEvent.dispatch(events.bimbear.loaded, true)

    useEvent.listen(events.configurator.optionUpdate, handleUpdate)

    init3dToggleButton()
  } catch (error) {
    // no product 3d
    console.log('couldnt load 3d', error)
    useEvent.dispatch(events.bimbear.loaded, false)
  }

  return () => {
    if (__devmode) {
      console.log('main-product-bimbear-viewer teardown start')
    }

    // remove, but save, <canvas> somewhere in <body>,
    // to be used next time there's a 3d view needed
    bimbear.unmount()

    if (__devmode) {
      console.log('main-product-bimbear-viewer teardown finish')
    }
  }
}
