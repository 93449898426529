import { events } from '../events'
import { useEvent, useRefs } from '../framework'
import { debounce, zeroPad } from '../utils'

async function updateAndRefreshCart(id, quantity) {
  const url = window.Shopify.routes.root + 'cart/update.js'

  const cart = document.querySelector('.cart')

  cart.classList.add('loading')

  try {
    let json = null

    if (typeof id !== 'undefined' && typeof quantity !== 'undefined') {
      const res = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          updates: {
            [id]: quantity,
          },
        }),
      })

      json = await res.json()
    }

    const data = await fetch('/?sections=cart')
    const { cart } = await data.json()

    const parser = new DOMParser()
    const markup = parser.parseFromString(cart, 'text/html')

    const newPage = markup.querySelector('.cart-dynamic-content')
    const oldPage = document.body.querySelector('.cart-dynamic-content')

    oldPage.innerHTML = newPage.innerHTML

    const refs = useRefs({ target: oldPage, asArray: true })

    hydrateCart(refs)

    if (json) {
      useEvent.dispatch(events.cart.updated, json)
    }
  } catch (error) {
    console.log(error)
  }

  cart.classList.remove('loading')

  window.location.reload()
}

const updateAndRefreshCartDebounced = debounce((...args) => {
  updateAndRefreshCart(...args)
}, 500)

function hydrateCart(ref) {
  if (!ref.cartQuantity) return

  /**
   * fix bug that didn't show product that was just added to cart
   * (when adding to cart and navigating to card within a second or 2)
   */
  // setTimeout(() => {
  //   if (!window.location.search.includes('updated')) {
  //     updateAndRefreshCart()
  //     const url = new URL(window.location)
  //     url.searchParams.set('updated', 'true')
  //     window.history.replaceState(null, '', url.toString())
  //   }
  // }, 1000)

  ref.cartQuantity.forEach(input => {
    const buttons = [...input.querySelectorAll('button')]
    const outputs = [...input.querySelectorAll('[data-quantity-output]')]

    buttons.forEach(button => {
      button.addEventListener('click', async e => {
        const newQuantity =
          parseInt(outputs[0].textContent) + parseInt(button.value)

        outputs.forEach(output => {
          output.textContent = zeroPad(Math.max(0, newQuantity))
        })

        try {
          updateAndRefreshCartDebounced(input.dataset.variant, newQuantity)
        } catch (error) {
          console.log(error)
        }
      })
    })
  })
}

export default ref => {
  hydrateCart(ref)
}
