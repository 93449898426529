import { getChosenVariant } from '../configurator'
import { events } from '../events'
import { useEvent } from '../framework'
import { debounce } from '../utils'

let timeoutID
function setup(addToCart) {
  timeoutID = setTimeout(() => {
    // console.log('timeout handling', timeoutID)
    addToCart.classList.add('step-one')
    addToCart.classList.remove('step-two')
  }, 2000)
}

function cancel() {
  // console.log('cancel timeout', timeoutID)
  clearTimeout(timeoutID)
}

function getIdInUrlParam() {
  const urlParams = new URLSearchParams(window.location.search)
  const id = urlParams.get('id')

  return id
}

const addToCartDebounced = debounce(async quantity => {
  try {
    useEvent.dispatch(events.cart.loading, true)
    const chosenVariant = await getChosenVariant()

    const url = window.Shopify.routes.root + 'cart/change.js'
    const effectiveQuantity = Math.max(quantity, 0)

    const res = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: chosenVariant.id.toString(),
        quantity: effectiveQuantity,
      }),
    })

    const json = await res.json()
    useEvent.dispatch(events.cart.updated, json)
    useEvent.dispatch(events.cart.loading, false)
  } catch (error) {
    console.log(error)
    return 'nope'
  }
}, 1000)

export default ref => {
  if (!ref.addToCart) return

  ref.addToCart.forEach(container => {
    // show add to cart container
    async function handleIsBuyable() {
      const chosenVariant = await getChosenVariant()
      const { available } = chosenVariant || {}

      if (!chosenVariant || !available) {
        container.parentElement.classList.remove('minimized')
        container.classList.remove('visible')
        return
      }

      container.parentElement.classList.add('minimized')
      container.classList.add('visible')

      if (ref.addToCartPrice && available) {
        ref.addToCartPrice.forEach(element => {
          element.textContent = chosenVariant.price
        })
      }

      handleShowingQuantitySelector(chosenVariant.id)
    }

    // toggle the quantity selector
    async function handleShowingQuantitySelector(variantId) {
      useEvent.dispatch(events.cart.loading, true)
      const res = await fetch(window.routes.cart_url + '.json', {
        headers: { 'Content-Type': 'application/json' },
      })

      const cart = await res.json()

      const variantInCart = cart.items.find(variant => {
        return variant.id === variantId
      })

      container.classList.toggle('step-two', !!variantInCart)
      const quantitySelector = container.querySelector('.step-two')
      const currentAmount = quantitySelector.querySelector('output')
      currentAmount.textContent = variantInCart?.quantity || 0
      useEvent.dispatch(events.cart.loading, false)
    }

    function setupQuantitySelector() {
      const stepTwo = container.querySelector('.step-two')
      const quantityInputs = [...stepTwo.querySelectorAll('button')]

      quantityInputs.forEach(async input => {
        input.addEventListener('click', async e => {
          const quantitySelector = container.querySelector('.step-two')
          const output = quantitySelector.querySelector('output')

          const newQuantity =
            parseInt(output.textContent) + parseInt(input.value)

          output.textContent = Math.max(0, newQuantity)

          try {
            addToCartDebounced(newQuantity)
          } catch (error) {
            console.log(error)
          }
        })
      })
    }

    handleIsBuyable()
    setupQuantitySelector()

    function handleAddToCart() {
      const addToCartButton = container.querySelector('.step-one')

      addToCartButton.addEventListener('click', async () => {
        useEvent.dispatch(events.cart.loading, true)

        const chosenVariant = await getChosenVariant()
        const url = window.Shopify.routes.root + 'cart/add.js'

        const res = await fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            items: [{ id: chosenVariant.id, quantity: 1 }],
          }),
        })

        const json = await res.json()

        handleShowingQuantitySelector(chosenVariant.id)

        useEvent.dispatch(events.cart.loading, false)
        useEvent.dispatch(events.cart.updated, json)
      })
    }

    handleAddToCart()

    function handleLoading({ detail }) {
      container.style.opacity = detail ? 0.2 : 1
    }

    useEvent.listen(events.cart.loading, handleLoading)
    useEvent.listen(events.configurator.optionUpdate, handleIsBuyable)
  })
}
