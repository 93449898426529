import { useEvent, useRefs } from '../framework'
import { events } from '../events'

export default (ref, __devmode) => {
  if (!ref.giftCard) return

  const teardowns = ref.giftCard.map(form => {
    let refs = useRefs({ target: form, asArray: true })

    async function handleClose(accordion) {
      const { height } = getComputedStyle(accordion)
      accordion.classList.remove('open')
      refs.button.forEach(button => button.classList.remove('active'))

      accordion.style.height = height

      setTimeout(() => {
        accordion.style.transition = 'height 300ms ease-in-out'
        accordion.style.height = '0px'

        setTimeout(() => accordion.style.removeProperty('height'), 300)
      })
    }

    async function handleOpen(accordion) {
      accordion.classList.add('open')
      refs.button.forEach(button => button.classList.add('active'))

      const { height } = getComputedStyle(accordion)

      accordion.style.height = '0px'

      setTimeout(() => {
        accordion.style.transition = 'height 300ms ease-in-out'
        accordion.style.height = height

        setTimeout(() => accordion.style.removeProperty('height'), 300)
      })
    }

    function handleClick() {
      refs.accordion.forEach(accordion => {
        if (!accordion.classList.contains('open')) {
          handleOpen(accordion)
        } else {
          handleClose(accordion)
        }
      })
    }

    function handleOption(option) {
      const { id } = option.dataset

      refs.button.forEach(button => {
        const span = button.querySelector('span')
        button.value = id
        span.textContent = option.textContent
      })

      refs.accordion.forEach(handleClose)
    }

    refs.button.forEach(button => {
      button.addEventListener('click', handleClick)
    })

    refs.option.forEach(option => {
      option.addEventListener('click', () => handleOption(option))
    })

    async function addToCart(e) {
      e.preventDefault()

      const url = window.Shopify.routes.root + 'cart/add.js'
      const id = refs.button[0].value

      const res = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          items: [{ id, quantity: 1 }],
        }),
      })

      const json = await res.json()

      useEvent.dispatch(events.cart.updated, json)

      // console.log(json)
    }

    refs.root.addEventListener('submit', addToCart)

    return () => {
      refs = null
    }
  })

  return () => {
    if (__devmode) {
      console.log('giftcard teardown start')
    }
    teardowns.map(teardown => teardown())
    if (__devmode) {
      console.log('giftcard teardown finish')
    }
  }
}
